import * as React from 'react';
import { ScheduleEntry } from '../../../domain/ScheduleEntry';
import ScheduleTableBlockHeader from './ScheduleTableBlockHeader';
import ScheduleTableSingleRow from './ScheduleTableSingleRow';
import ScheduleTableCollapseRow from './ScheduleTableCollapseRow';
import ScheduleTableBlockIntroHeader from './ScheduleTableBlockIntroHeader';
import { ScheduleWeek } from '../../../domain/ScheduleWeek';

interface Props {
    name: string;
    week?: ScheduleWeek;
    entries: ScheduleEntry[];
    assigned: number[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

interface EntryObject {
    name: string;
    entries: ScheduleEntry[];
}

export default (props: Props) => {
    const [entries, setEntries] = React.useState<EntryObject[]>([]);

    React.useEffect(() => {
        const merged: EntryObject[] = [];
        props.entries.forEach(entry => {
            const same = merged.find(obj => obj.name === entry.name);
            if (same) {
                same.entries.push(entry);
            } else {
                const obj = { name: entry.name, entries: [] } as EntryObject;
                obj.entries.push(entry);
                merged.push(obj)
            }
        });
        setEntries(merged);
    }, [props.entries]);

    return (
        <>
            {props.name === "intro" ? <ScheduleTableBlockIntroHeader name={props.week && props.week.name} /> : <ScheduleTableBlockHeader name={props.name!} />}
            {entries.map((object: EntryObject) => {
                if (object.entries.length === 1) {
                    return (
                        <ScheduleTableSingleRow
                            key={object.name}
                            entry={object.entries[0]}
                            assigned={props.assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />)
                } else {
                    return (
                        <ScheduleTableCollapseRow
                            key={object.name}
                            entries={object.entries}
                            assigned={props.assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory}
                        />
                    )
                }
            })}
        </>
    );
};