import * as React from 'react';
import Panel from '../panel/Panel';
import { Button, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import TerritoriesManagementTab from './management/TerritoriesManagementTab';
import Can from '../../components/Can';
import { isMobile } from 'react-device-detect';
import { useStores } from '../../stores';
import TerritoriesAssignmentTab from './assignments/TerritoriesAssignmentTab';
import MobileTerritoriesManagementTab from './management/MobileTerritoriesManagementTab';


export default observer(() => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);
    const fileInput = React.useRef<HTMLInputElement>(null);
    const { territoryStore } = useStores();

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    const tabMenu = [
        <Tab key="territories" label={t("territories")} />,
        <Tab key="assignments" label={t("appointments")} />
    ];

    const uploadJson = (event: any) => {
        territoryStore.uploadJson(event.target.files);
    }

    const headerItems = () => {
        const items = [];
        if (!isMobile) {
            items.push(
                <Can key="upload_json" do="manage" on='Territory'>
                    <Button
                        color="inherit"
                        type="button"
                        onClick={() => fileInput.current && fileInput.current.click()}>
                        {t("upload_json")}
                        <input ref={fileInput} type="file" hidden onChange={uploadJson} />
                    </Button>
                </Can>
            );
        }

        return items;
    }

    return (
        <Panel title={t("settings")} headerItems={headerItems()}>
            <Tabs value={tab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {tabMenu}
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                {isMobile ? <MobileTerritoriesManagementTab /> : <TerritoriesManagementTab />}
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <TerritoriesAssignmentTab />
            </div>
        </Panel >
    );
});