import { Table, TableBody, TableContainer } from '@mui/material';
import { ScheduleEntry } from '../../domain/ScheduleEntry';
import ScheduleTableBlock from './schedule-table/ScheduleTableBlock';
import { ScheduleWeek } from '../../domain/ScheduleWeek';
import { prepareAssigned } from './utils/scheduleUtils';

interface Props {
    week?: ScheduleWeek;
    entries: ScheduleEntry[];
    editable?: boolean;
    onEntryUpdate?: (entry: ScheduleEntry) => void;
    onEntryEdit?: (entry: ScheduleEntry) => void;
    onEntryHistory?: (entry: ScheduleEntry) => void;
}

export default (props: Props) => {
    const assigned = prepareAssigned(props.entries);
    return (
        <>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <ScheduleTableBlock
                            name="intro"
                            week={props.week}
                            entries={props.entries.filter(entry => entry.subType === "intro")}
                            assigned={assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory} />
                        <ScheduleTableBlock
                            name="treasures"
                            week={props.week}
                            entries={props.entries.filter(entry => entry.subType === "treasures")}
                            assigned={assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory} />
                        <ScheduleTableBlock
                            name="apply-yourself"
                            week={props.week}
                            entries={props.entries.filter(entry => entry.subType === "apply-yourself")}
                            assigned={assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory} />
                        <ScheduleTableBlock
                            name="living-as-christians"
                            week={props.week}
                            entries={props.entries.filter(entry => entry.subType === "living-as-christians")}
                            assigned={assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory} />
                        <ScheduleTableBlock
                            name="assignment"
                            week={props.week}
                            entries={props.entries.filter(entry => entry.subType === "assignment")}
                            assigned={assigned}
                            editable={props.editable}
                            onEntryUpdate={props.onEntryUpdate}
                            onEntryEdit={props.onEntryEdit}
                            onEntryHistory={props.onEntryHistory} />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};