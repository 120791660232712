import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import { Territory } from '../../../domain/Territory';
import { Publisher } from '../../../domain/Publisher';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import ReturnTerritoryDialog from '../dialogs/ReturnTerritoryDialog';


export default observer(() => {
    const { t } = useTranslation();
    const { publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();

    React.useEffect(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, []);

    const openReturnTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory")
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }
    
    const handleTerritoryReturned = () => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            {publishers.map(publ => {
                return <Accordion key={publ.id} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography><b>{t("publisher")}: </b>{`${publ.lastName} ${publ.firstName}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("territory")}</TableCell>
                                        {!isMobile && <TableCell>{t("notes")}</TableCell>}
                                        <TableCell>{t("taken_at")}</TableCell>
                                        <TableCell>{t("actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {publ.territoryAssignments!.map(assign => {
                                        return <TableRow key={assign.id}>
                                            <TableCell>
                                                {assign.territory!.title}
                                            </TableCell>
                                            {!isMobile && <TableCell>
                                                {assign.notes}
                                            </TableCell>}
                                            <TableCell>
                                                {moment(assign.takenAt).format("YYYY-MM")}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => openReturnTerritoryDialog(assign.territory!)}>
                                                    <KeyboardReturnIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            })}
            {isOpen("return-territory") && <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />}
        </Container>
    );
});