import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { FeatureGroup, MapContainer, Polygon, TileLayer, Tooltip } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Territory } from '../../../domain/Territory';
import L, { DrawEvents, LatLngExpression } from 'leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { MapViewChange } from '../map/MapViewChange';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "react-leaflet-fullscreen/styles.css";
import "./TerritoryMap.css"


interface Props {
    polygons: {
        id: number,
        title?: string,
        color?: string,
        bounds: LatLngExpression[],
        territory: Territory,
    }[];
    style: React.CSSProperties;
    tooltipOnHover?: boolean;
    scrollWheelZoom?: boolean;
    currentTerritory?: Territory;
    editing?: boolean;
    onPolygonClick?: (terrotory: Territory) => void;
    onPolygonCreate?: (bounds: LatLngExpression[][]) => void;
    onPolygonEdit?: (bounds: LatLngExpression[][]) => void;
    onPolygonDelete?: (bounds: LatLngExpression[]) => void;
}

const defaultCenter = process.env.REACT_APP_TERRITORIES_DEFAULT_LOCATION!.split(',').map(Number) as LatLngExpression;

export default observer((props: Props) => {
    const [focusedShape, setFocusedShape] = React.useState<LatLngExpression[]>([]);

    React.useEffect(() => {
        if (props.currentTerritory) {
            if (props.currentTerritory.boundary.length > 0) {
                const arr = JSON.parse(props.currentTerritory.boundary)
                if (arr.length) {
                    const shape = arr[0] as LatLngExpression[]; // first shape from array of shapes
                    setFocusedShape(shape);
                }
            }
        }
    }, [props.currentTerritory]);

    const createShape = (e: DrawEvents.Created) => {
        const layer = e.layer as any;
        props.onPolygonCreate && props.onPolygonCreate(layer.getLatLngs());
    }

    const editShapes = (e: any) => {
        const layer = e.layer as any;
        props.onPolygonEdit && props.onPolygonEdit(layer.getLatLngs());
    }

    const deleteShape = (e: any) => {
        console.log(e);
    }

    return (
        <MapContainer style={props.style} center={defaultCenter} zoom={13} scrollWheelZoom={!!props.scrollWheelZoom}>
            <MapViewChange shape={focusedShape} />

            {props.editing && (
                <FeatureGroup>
                    <EditControl position='topright' onCreated={createShape} onEdited={editShapes} onDeleted={deleteShape} draw={{
                        rectangle: false,
                        polyline: false,
                        circle: false,
                        circlemarker: false,
                        marker: false
                    }} />
                </FeatureGroup>
            )}
            <FullscreenControl />

            {props.polygons.map(polygon => (
                <Polygon
                    key={polygon.id}
                    color={polygon.color || '#1976d2'}
                    positions={polygon.bounds}
                    eventHandlers={{ click: () => props.onPolygonClick && props.onPolygonClick(polygon.territory) }}
                >
                    {!!props.tooltipOnHover && <Tooltip sticky>{polygon.title}</Tooltip>}
                </Polygon>
            ))}

            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </MapContainer>
    );
});