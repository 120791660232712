import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { Territory } from '../domain/Territory';
import { TerritoryGroup } from '../domain/TerritoryGroup';

export class TerritoryStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    groups: TerritoryGroup[] = [];
    publisherTerritories: {
        [publisherId: number]: Territory[];
    } = {};

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getMyTerritories = (callback: (data: any) => void) => {
        const myPublisher = this.rootStore.publisherStore.myPublisher;
        if (myPublisher) {
            this.serverStore.get(`/territory?publisherId=${myPublisher.id}`, action((data) => {
                this.publisherTerritories[myPublisher.id] = data;
                callback(data);
            }));
        }
    }

    getAllGroups = (callback?: () => void) => {
        this.serverStore.get('/territory-group', action((groups: TerritoryGroup[]) => {
            this.groups = groups;
            callback && callback();
        }));
    }

    getPublisherTerritories = (publisherId: number) => {
        this.serverStore.get(`/territory?publisherId=${publisherId}`, action((territories: Territory[]) => {
            this.publisherTerritories[publisherId] = territories;
        }));
    }

    createTerritory = (obj: any) => {
        this.serverStore.post('territory', obj, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_created_successfully", "success");
        }))
    }

    updateTerritory = (id: number, obj: any) => {
        this.serverStore.put(`territory/${id}`, obj, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_updated_successfully", "success");
        }))
    }

    deleteTerritory = (id: number) => {
        this.serverStore.delete(`/territory/${id}`, action(() => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_deleted_successfully", "success");
        }));
    }

    getAssignments = (id: number, callback: (data: any) => void) => {
        this.serverStore.get(`/territory-assignment?territoryId=${id}`, action((data) => {
            callback(data);
        }));
    }

    createTerritoryGroup = (obj: TerritoryGroup) => {
        this.serverStore.post('territory-group', obj, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_group_was_created_successfully", "success");
        }))
    }

    updateTerritoryGroup = (id: number, obj: TerritoryGroup) => {
        const { territories, ...rest } = obj;
        this.serverStore.put(`territory-group/${id}`, rest, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_group_was_updated_successfully", "success");
        }))
    }

    deleteTerritoryGroup = (id: number) => {
        this.serverStore.delete(`/territory-group/${id}`, action(() => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_group_was_deleted_successfully", "success");
        }));
    }

    isTerritoryAssigned = (territoryId: number, callback: (result: boolean) => void) => {
        this.serverStore.get(`territory-assignment/check?territoryId=${territoryId}`, action((result) => {
            callback(result)
        }))
    }

    assignTerritory = (territoryId: number, publisherId: number, notes?: string) => {
        this.serverStore.post('territory-assignment', { territoryId, publisherId, notes }, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_assigned_successfully", "success");
        }), () => {
            this.rootStore.notificationsStore.add("failed_to_assign_the_territory", "error");
        })
    }

    returnTerritory = (territoryId: number, unworked?: boolean, callback?: () => void) => {
        this.serverStore.post('territory-assignment/return', { territoryId, unworked }, action((data) => {
            this.getAllGroups();
            this.rootStore.notificationsStore.add("the_territory_was_returned_successfully", "success");
            callback && callback();
        }), () => {
            this.rootStore.notificationsStore.add("failed_to_return_the_territory", "error");
        })
    }

    uploadJson = (file: any, callback?: () => void) => {
        this.serverStore.upload('/territory/upload', file[0], action(() => {
            this.rootStore.notificationsStore.add('json_is_uploaded', "success");
            this.getAllGroups();
            callback && callback();
        }));
    }
}