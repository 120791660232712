import L, { LatLngExpression } from "leaflet";

export const stringToLatLng = (str: string): LatLngExpression[] => {
    return JSON.parse(str) as LatLngExpression[];
}

export const getShapeCenter = (shape: LatLngExpression[]): L.LatLng => {
    const polygon = L.polygon(shape);
    const bounds = polygon.getBounds();
    return bounds.getCenter();
}