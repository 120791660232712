import * as React from 'react';
import { Box, Collapse, IconButton, Paper, TableCell, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { isMobile } from 'react-device-detect';
import { Territory } from '../../../domain/Territory';
import { LatLngExpression } from 'leaflet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DirectionsIcon from '@mui/icons-material/Directions';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TerritoryMap from '../map/TerritoryMap';

interface Props {
    territory: Territory;
    onTerritoryReturn: (territory: Territory) => void;
    onTerritoryDirections: (territory: Territory) => void;
}

export default observer((props: Props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow>
                <TableCell padding='none'>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {props.territory.title}
                </TableCell>
                {!isMobile && <TableCell>
                    {props.territory.territoryGroup!.name}
                </TableCell>}
                <TableCell padding='none'>
                    <IconButton onClick={() => props.onTerritoryDirections(props.territory)}>
                        <DirectionsIcon />
                    </IconButton>
                    <IconButton onClick={() => props.onTerritoryReturn(props.territory)}>
                        <KeyboardReturnIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography gutterBottom component="div">
                                {props.territory.notes}
                            </Typography>
                            <Paper style={{ height: "65vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                                <TerritoryMap 
                                    style={{ height: "100%", width: "100%" }}
                                    polygons={[{ 
                                        id: props.territory.id,
                                        bounds: JSON.parse(props.territory.boundary)[0] as LatLngExpression[],
                                        territory: props.territory
                                    }]}
                                    currentTerritory={props.territory}
                                />
                            </Paper>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
});