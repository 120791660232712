import * as React from 'react';
import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import { useConfirm } from 'material-ui-confirm';
import { MuiColorInput } from 'mui-color-input';

interface Props {
    group?: TerritoryGroup
    isOpen: boolean;
    onClose: () => void;
}

interface FormValues {
    name: string;
    notes: string;
    color: string;
    hidden: boolean;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();
    const [color, setColor] = React.useState<string>(props.group ? (props.group.color || '') : '');
    const confirm = useConfirm();

    const schema = yup.object().shape({
        name: yup.string(),
        notes: yup.string(),
        hidden: yup.bool()
    });

    let initialValues = { name: '', notes: '', hidden: false } as FormValues;
    if (props.group) {
        initialValues = { ...props.group } as FormValues;
    }

    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            const group = { ...values, color };
            if (props.group) {
                territoryStore.updateTerritoryGroup(props.group.id, group as TerritoryGroup);
            } else {
                territoryStore.createTerritoryGroup(group as TerritoryGroup);
            }
            props.onClose();
        },
    });

    const handleDelete = () => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            territoryStore.deleteTerritoryGroup(props.group!.id);
            props.onClose();
        });
    }

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} fullWidth={true} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("manage_territory_group")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name && errors.name as string}
                        value={values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="notes"
                        label={t("notes")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.notes}
                        helperText={errors.notes && errors.notes as string}
                        value={values.notes || ''}
                        onChange={formik.handleChange}
                    />

                    <MuiColorInput
                        id="color"
                        margin="normal"
                        label={t("color")}
                        fullWidth
                        variant="standard"
                        format="hex"
                        isAlphaHidden={true}
                        value={color}
                        onChange={setColor}
                    />

                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleDelete}>{t("delete")}</Button>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});