import { Divider, IconButton, List, Toolbar } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Link } from "react-router-dom";
import Can from '../../components/Can';
import { useTranslation } from "react-i18next";
import { isMobileOnly } from 'react-device-detect';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: any) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default observer(() => {
    const { t } = useTranslation();
    const { panelStore, userStore } = useStores();
    const toggleDrawer = () => {
        panelStore.setCollapsed(!panelStore.sideBarCollapsed);
    };

    const handleLogoutClick = () => {
        userStore.logout();
    }

    const hidden = isMobileOnly ? panelStore.sideBarCollapsed : false;
    return (
        <Drawer variant="permanent" hidden={hidden} open={!panelStore.sideBarCollapsed} >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <ListItemButton id="side_menu_item_home" component={Link} to="/home">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home")} />
                </ListItemButton>
                <Can do="read" on='Publishers'>
                    <ListItemButton id="side_menu_item_publishers" component={Link} to="/publishers">
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("publishers")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Cart'>
                    <ListItemButton id="side_menu_item_carts" component={Link} to="/carts">
                        <ListItemIcon>
                            <DocumentScannerOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("carts")} />
                    </ListItemButton>
                </Can>
                <ListItemButton id="side_menu_item_my_reports" component={Link} to="/my-reports">
                    <ListItemIcon>
                        <SummarizeTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("my_reports")} />
                </ListItemButton>
                <Can do="read" on='GroupReports'>
                    <ListItemButton id="side_menu_item_group_reports" component={Link} to="/group-reports">
                        <ListItemIcon>
                            <SummarizeIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("group_reports")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='OverallReport'>
                    <ListItemButton id="side_menu_item_overall_report" component={Link} to="/overall-report">
                        <ListItemIcon>
                            <SummarizeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("overall_report")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Attendance'>
                    <ListItemButton id="side_menu_item_attendance" component={Link} to="/attendance">
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("attendance")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Groups'>
                    <ListItemButton id="side_menu_item_groups" component={Link} to="/groups">
                        <ListItemIcon>
                            <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("groups")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Literature'>
                    <ListItemButton id="side_menu_item_literature" component={Link} to="/literature">
                        <ListItemIcon>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("literature")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='ScheduleEntry'>
                    <ListItemButton id="side_menu_item_schedule" component={Link} to="/schedule">
                        <ListItemIcon>
                            <CalendarTodayIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("schedule")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Knowledge'>
                    <ListItemButton id="side_menu_item_knowledge" component={Link} to="/knowledge">
                        <ListItemIcon>
                            <SchoolIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("knowledge")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Territory'>
                    <ListItemButton id="side_menu_item_my_territory" component={Link} to="/my-territory">
                        <ListItemIcon>
                            <MapsHomeWorkIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("my_territories")} />
                    </ListItemButton>
                </Can>
                <Can do="manage" on='Territory'>
                    <ListItemButton id="side_menu_item_territory" component={Link} to="/territory">
                        <ListItemIcon>
                            <MapIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("territories")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Users'>
                    <ListItemButton id="side_menu_item_users" component={Link} to="/users">
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("users")} />
                    </ListItemButton>
                </Can>
                <Divider sx={{ my: 1 }} />
                <Can do="update" on='Settings'>
                    <ListItemButton id="side_menu_item_settings" component={Link} to="/settings">
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("settings")} />
                    </ListItemButton>
                </Can>
                <Can do="manage" on='Activity'>
                    <ListItemButton id="side_menu_item_activity" component={Link} to="/activity">
                        <ListItemIcon>
                            <PeopleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("activity")} />
                    </ListItemButton>
                </Can>
                <ListItemButton id="side_menu_item_logout" onClick={handleLogoutClick} >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("logout")} />
                </ListItemButton>
            </List>
        </Drawer >
    )
})
