import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { LatLngExpression } from 'leaflet';
import { Territory } from '../../../domain/Territory';
import TerritoryMap from '../map/TerritoryMap';

interface Props {
    territory: Territory;
    isOpen: boolean;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();

    const bounds = JSON.parse(props.territory.boundary) as LatLngExpression[][];
    return (
        <Dialog open={props.isOpen} fullWidth={true} maxWidth="xl" onClose={props.onClose}>
            <Container maxWidth="xl">
                <DialogTitle>{t("territory")}</DialogTitle>
                <DialogContent>
                    <Box style={{ height: "60vh" }}>
                        <TerritoryMap
                            style={{ height: "100%", width: "100%" }}
                            polygons={bounds.map((arr: LatLngExpression[], i: number) => ({
                                id: i,
                                bounds: arr,
                                territory: props.territory!
                            }))}
                            currentTerritory={props.territory}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                </DialogActions>
            </Container>
        </Dialog >
    );
});