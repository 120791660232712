import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { useTranslation } from "react-i18next";
import { FieldServiceGroup } from '../../domain/FieldServiceGroup';
import GroupReportsTable from './GroupReportsTable';
import { useNavigate, useParams } from 'react-router-dom';
import { Publisher } from '../../domain/Publisher';
import { Button, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export default observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { publisherStore, reportStore, panelStore, serverStore } = useStores();
    const groupId = Number(useParams().id);
    const [date, setDate] = React.useState<Date>(panelStore.groupReportPage.date);
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const [name, setName] = React.useState<string>("");

    React.useEffect(() => {
        let overseerGroup: FieldServiceGroup | undefined;
        if (groupId) {
            overseerGroup = publisherStore.groups.find(gr => gr.id === groupId);
        } else if (publisherStore.myPublisher) {
            const id = publisherStore.myPublisher.id;
            overseerGroup = publisherStore.groups.find(gr => gr.overseer.id === id);
            if (!overseerGroup) {
                overseerGroup = publisherStore.groups.find(gr => gr.assistant && gr.assistant.id === id);
            }
        }
        if (overseerGroup) {
            publisherStore.getServiceGroup(overseerGroup.id, date, (group: FieldServiceGroup) => {
                if (group.publishers) {
                    const groupPublishers = group.publishers
                        .filter((publisher: Publisher) => !publisher.inactive)
                        .sort((a: Publisher, b: Publisher) => a.lastName.localeCompare(b.lastName));
                    setPublishers(groupPublishers);
                }
                setName(`${overseerGroup!.overseer.firstName} ${overseerGroup!.overseer.lastName}`);
            });
        }
    }, [publisherStore.groups, publisherStore.myPublisher, groupId, date]);

    const downloadExcel = () => {
        publisherStore.downloadGroupExcel(groupId, date);
    }

    const handleSubmit = (report: FieldServiceReport) => {
        reportStore.saveS4Report(report);
    }

    const handleDateChange = (date: Date) => {
        setDate(date);
    }

    const handleGroupChange = (event: SelectChangeEvent) => {
        navigate(`/group-reports/${event.target.value}`)
    };

    const headerItems = [
        <Select
            key="group-dropdown"
            style={{ backgroundColor: "#FFFFFFCC", borderRadius: "10px", height: 40 }}
            id="groups"
            value={groupId + ""}
            onChange={handleGroupChange}
        >
            {publisherStore.groups && publisherStore.groups.map(group =>
                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)}
        </Select>
    ];
    if (groupId) {
        headerItems.unshift(
            <Button
                key="download-group-excel-btn"
                color="inherit"
                type="button"
                onClick={downloadExcel}>
                {t("download_excel")}
            </Button>
        )
    }

    return (
        <Panel title={t("group_reports")}
            headerItems={headerItems}
        >
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {name && <h3>{`${t("overseer")}: ${name}`}</h3>}

                    <div style={{ height: 700, width: '100%' }} >
                        {serverStore.serverRequestLoading && <LinearProgress />}
                        <GroupReportsTable
                            publishers={publishers}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                        />
                    </div>
                </Paper>
            </Container>
        </Panel >
    );
});