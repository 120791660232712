import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { useTranslation } from "react-i18next";
import GroupReportsTable from './GroupReportsTable';
import GenerateS1Dialog from '../../components/GenerateS1Dialog';
import Can from '../../components/Can';
import { Publisher, ServiceAssignment } from '../../domain/Publisher';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

export default observer(() => {
    const { t } = useTranslation();
    const { publisherStore, reportStore, panelStore, serverStore } = useStores();
    const [date, setDate] = React.useState<Date>(panelStore.groupReportPage.date);
    const [assignment, setAssignment] = React.useState<ServiceAssignment | string>(panelStore.groupReportPage.filter);
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const navigate = useNavigate();
    const [openS1, setOpenS1] = React.useState(false);

    React.useEffect(() => {
        publisherStore.getPublishersWithReports((data) => {
            setPublishers(data);
        });
    }, []);

    const openPivotReports = () => {
        navigate('/pivot-reports');
    }

    const handleS1Open = () => {
        setOpenS1(true);
    };

    const handleCloseS1 = () => {
        setOpenS1(false);
    };

    const downloadExcel = () => {
        reportStore.downloadAssignmentExcel(assignment, date);
    }

    const handleDateChange = (date: Date) => {
        setDate(date);
    }

    const handleAssignmentChange = (assignment: ServiceAssignment | string) => {
        setAssignment(assignment);
    }

    const handleSubmit = (report: FieldServiceReport) => {
        reportStore.saveS4Report(report, () => {
            publisherStore.getAllPublishers(true, (data) => {
                setPublishers(data);
            });
        });
    }

    const headerItems = () => {
        const items = [];
        if (!isMobile) {
            items.push(
                <Can key="btn_download_s1" do="create" on='S1Report'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={handleS1Open}>
                        {t("download_s_1")}
                    </Button>
                </Can>
            );

            items.push(
                <Button
                    key="download-group-excel-btn"
                    color="inherit"
                    type="button"
                    onClick={downloadExcel}>
                    {t("download_excel")}
                </Button>
            )
        }

        items.push(
            <Button
                key="pivot_reports_btn"
                type="button"
                color="inherit"
                onClick={openPivotReports}>
                {t("pivot_reports")}
            </Button>
        );
        return items;
    }

    return (
        <Panel
            title={t("overall_report")}
            headerItems={headerItems()}
        >
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: 1000, width: '100%' }} >
                        {serverStore.serverRequestLoading && <LinearProgress />}
                        <GroupReportsTable
                            publishers={publishers}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                            onAssignmentChange={handleAssignmentChange}
                        />
                    </div>
                </Paper>
            </Container>
            <GenerateS1Dialog
                isOpen={openS1}
                onClose={handleCloseS1} />
        </Panel >
    );
});