import L, { LatLngExpression } from "leaflet";
import { useMap } from "react-leaflet";

interface Props {
    shape: LatLngExpression[]
}

export const MapViewChange = (props: Props) => {
    const map = useMap();
    if (props.shape.length > 0) {
        console.log(props.shape);
        const bounds = L.latLngBounds(props.shape);
        map.fitBounds(bounds);
    }

    return null;
}