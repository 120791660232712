import * as React from 'react';
import { Collapse, IconButton, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import SquareIcon from '@mui/icons-material/Square';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import Can from '../../../components/Can';
import { Territory } from '../../../domain/Territory';
import { isMobile } from 'react-device-detect';

interface Props {
    group: TerritoryGroup;
    onGroupEdit: (group: TerritoryGroup) => void;
    onGroupHide: (group: TerritoryGroup) => void;
    onTerritoryFocus: (territory: Territory) => void;
    onTerritoryAssign: (territory: Territory) => void;
    onTerritoryCreate: () => void;
    onTerritoryEdit: (еerritory: Territory) => void;
}

export default (props: Props) => {
    const [open, setOpen] = React.useState(false);

    const handleGroupHide = () => {
        const group = { ...props.group, hidden: !props.group.hidden };
        props.onGroupHide(group)
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <SquareIcon style={{ color: props.group.color }} />
                        <Typography variant="body1">{props.group.name}</Typography>
                    </Stack>
                </TableCell>
                <Can do="update" on='TerritoryGroup'>
                    <TableCell>
                        <IconButton onClick={() => props.onGroupEdit(props.group)}>
                            <EditIcon />
                        </IconButton>
                        {!isMobile && <Can do="update" on='TerritoryGroup'>
                            <IconButton onClick={handleGroupHide} >
                                {props.group.hidden ? <VisibilityOffIcon /> : <VisibilityIcon color="primary" />}
                            </IconButton>
                        </Can>}
                        {!isMobile && <Can do="create" on='Territory'>
                            <IconButton disabled={true} onClick={() => props.onTerritoryCreate()} >
                                <AddIcon />
                            </IconButton>
                        </Can>}
                    </TableCell>
                </Can>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table>
                                <TableBody>
                                    {props.group.territories && props.group.territories.map((territory, i) => (
                                        <TableRow key={territory.id}>
                                            <TableCell padding='none' >{i + 1}</TableCell>
                                            <TableCell onClick={() => props.onTerritoryFocus(territory)}>
                                                {`${territory.title}`}
                                            </TableCell>
                                            <TableCell padding='none'>
                                                {!isMobile && <IconButton size='small' onClick={() => props.onTerritoryEdit(territory)}>
                                                    <EditIcon />
                                                </IconButton>}
                                                <IconButton size='small' onClick={() => props.onTerritoryAssign(territory)}>
                                                    <AssignmentIndIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};